// NPM
import React from "react"

// Shared Components
import CommonHero from 'src/components/Shared/CommonHero'
import InfoColumns from 'src/components/Shared/InfoColumns'
import Partners from 'src/components/Shared/Partners'
import Timeline from 'src/components/Shared/Timeline'
import ProductsSection from 'src/components/Shared/ProductsSection'
import WorkTogether from 'src/components/Shared/WorkTogether';

const SurveyProducts = ({ sections, teamMembers, advisors }) => {
  return (
    <div className="SurveyProducts">
      <CommonHero section={ sections.find(s => s.uuid === "surveyProducts_title") } />
      <InfoColumns sections={ sections } />
      <Partners section={ sections.find(s => s.uuid === "partners") } />
      <Timeline section={ sections.find(s => s.uuid === "timeline") } />
      <ProductsSection sections={ sections } />
      <WorkTogether section={ sections.find(s => s.uuid === "workTogether") } />
    </div>
  )

}

export default SurveyProducts